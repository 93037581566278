import React, { useState, useEffect } from "react"
import Layout from "../../components/layout/layout.js"
import SEO from "../../components/seo.js"
import TopHeroComponent from "../../components/herocomponents/topherocomponent.js"
import ContactForm from "../../components/bodycomponents/contact-form.js"
import RED_BG from "../../images/background-images/bg-red.svg"
import { colors } from "../../utils/colors.js"
import SearchModule from "../../components/search2/searchModule.js"

const MedicalDevices = () => {
  const [searchFilters, setSearchFilters] = useState({
    hierarchicalMenu: { "categories.lvl0": "Lab Equipment > Medical Devices" }, // Ensure space around `>` matches your indexing
    page: 1,
  });

  return (
    <Layout>
      <SEO
        title={`Premium Medical Equipment | Surplus Solutions`}
        description={`Discover high-quality used refurbished medical equipment, including anesthesia machines, defibrillators, and more.`}
      />
      <TopHeroComponent
        backgroundImage={RED_BG}
        backgroundColor={colors.scarletRed}
        fontShadowColor={colors.red}
        shadowColor="shadow-red-bottom"
        pageTitle="Medical Equipment"
        title="Premium Used & Refurbished Medical Equipment"
        body={
          <p className="pt-4">
            Surplus Solutions offers high-quality new, used, and refurbished
            medical equipment for healthcare and clinical needs. From anesthesia
            machines to defibrillators and dialysis systems, our reliable
            inventory ensures top performance. Enhance patient care with trusted
            tools from leading manufacturers.
          </p>
        }
      />
      <SearchModule showManufacturer={true} searchFilters={searchFilters} />

      <ContactForm
        title="Never miss a great deal!"
        body={
          <p>
            Receive weekly emails with our special offers, new arrivals,
            auctions, case studies and more!
          </p>
        }
        fontShadowColor={colors.red}
        backgroundColor={colors.containerGray}
        shadow="shadow-red-bottom"
        formType="Marketing Sign Up"
      />
    </Layout>
  )
}

export default MedicalDevices
